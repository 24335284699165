import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { format } from "date-fns";

import { Bar, EColors, formatNumber, IconGrowDown, IconGrowUp, IError, Paper, roundDecimal, Skeleton } from "@funfarm/kit";

import i18n, { localeMap } from "helpers/i18n";
import { IPlayerTrolleyStats } from "types";
import { getPlayerTrolley } from "actions";

import { Helper } from "components/Helper";

import css from "./player.module.scss";


export const Indicators = () => {
    const { t } = useTranslation();

    const { isLoading, data } = useQuery<IPlayerTrolleyStats, IError>({
        queryKey: ["player", "trolley-stats"],
        queryFn: getPlayerTrolley
    });


    const getColorWBI = useCallback(() => {
        if (Number(data?.wr_ev_bb_100) < Number(data?.wr_ev_down)) {
            return EColors.red;
        }
        if (Number(data?.wr_ev_bb_100) < Number(data?.wr_ev_up)) {
            return EColors.white;
        }

        return EColors.green;
    }, [data]);


    return (
        <>
            <Paper className={classNames("indicator", css.card)}>
                <h4 className="header">
                    {t("EV Average")}
                    <Helper path="rangEvWbi" />
                </h4>
                {
                    isLoading ?
                        <>
                            <Skeleton size="xxxlarge" />
                            <Skeleton />
                        </> :
                        <>
                            <h2 className={css.barHeader}>
                                <b>{roundDecimal(data?.wr_ev_bb_100)}</b>
                            </h2>
                            {Number(data?.wr_ev_down) && Number(data?.wr_ev_up) ?
                                <Bar
                                    value={data?.wr_ev_bb_100}
                                    amount={15}
                                    points={[Number(data?.wr_ev_down), Number(data?.wr_ev_up)]}
                                    label={false}
                                    color={getColorWBI()} />

                                : <div className={css.barHeader}>{t("Does not have enough hands")}</div>}
                        </>
                }
            </Paper>


            <Paper className={classNames("indicator", css.card)}>
                <h4 className="header">
                    {t("Peak make")}
                    {/*<Helper path="peakMake" />*/}
                </h4>
                {
                    isLoading ?
                        <>
                            <Skeleton size="xxxlarge" />
                            <Skeleton />
                        </> :
                        <>
                            <h2 className={css.barHeader}>
                                <b>{formatNumber(Number(roundDecimal(data?.ath_make_abi)))}</b> {t("buyin", { count: data?.ath_make_abi })}
                            </h2>
                            <Bar value={data?.ath_make_abi} amount={data?.ath_make_boundary} label={false}
                                color={Number(data?.ath_make_abi) > Number(data?.ath_make_boundary) ? EColors.red : EColors.green} />

                            <div className="caption">{t("make_subtitle", { num: 300 })}</div>
                        </>
                }
            </Paper>
            <Paper className={classNames("indicator", css.card)}>
                <h4 className="header">
                    {t("EV 10lvl+ last 100k hands")}
                </h4>
                {
                    isLoading ?
                        <>
                            <Skeleton size="xxxlarge" />
                            <Skeleton />
                        </> :
                        <>
                            <h2 className={css.barHeader}>
                                <b>{formatNumber(Number(roundDecimal(data?.ev_bb_100_10lvl_plus)))}</b>
                            </h2>
                            <Bar value={data?.ev_bb_100_10lvl_plus} amount={data?.rang_avg_ev_bb_100_10lvl_plus} label={false}
                                color={Number(data?.ev_bb_100_10lvl_plus) > Number(data?.rang_avg_ev_bb_100_10lvl_plus) ? EColors.green : EColors.red} />
                            <div className={css.subRow}>
                                <div
                                    className="caption">{formatNumber(data?.hand_count_10lvl_plus)} {t("hand", { count: data?.hand_count_10lvl_plus })}</div>
                                <div
                                    className="caption">{formatNumber(data?.rang_avg_ev_bb_100_10lvl_plus)} {t("rank avg.", { count: data?.rang_avg_ev_bb_100_10lvl_plus })}</div>
                            </div>
                        </>
                }
            </Paper>
            <Paper className={classNames("indicator", css.card)}>
                <h4 className="header">
                    {t("Current rang")}
                </h4>
                {
                    isLoading ?
                        <>
                            <Skeleton size="xxxlarge" />
                            <Skeleton />
                        </> :
                        <div className="value">{data?.rank}</div>
                }
            </Paper>


            <Paper className={classNames("indicator", css.card)}>
                <h4 className="header">
                    {t("Number of hands on top")} 1
                    <Helper path="handsOnTop" />
                </h4>
                {
                    isLoading ?
                        <>
                            <Skeleton size="xxxlarge" />
                            <Skeleton />
                        </> :
                        <>
                            <h2 className={css.barHeader}>
                                <b>{formatNumber(data?.hand_count_cur_rank_minus)}</b> {t("hand", { count: data?.hand_count_cur_rank_minus })}
                            </h2>
                            <Bar value={data?.hand_count_cur_rank_minus} amount={data?.min_up_hand_count_minus} label={false}
                                color={Number(data?.hand_count_cur_rank_plus1) > 5000 ? EColors.green : EColors.red} />
                            <div className="caption">{t("Minimum")} 5000</div>
                        </>
                }
            </Paper>

            <Paper className={classNames("indicator", css.card)}>
                <h4 className="header">
                    {t("Number of hands on top")} 2
                    <Helper path="handsOnTop2" />
                </h4>
                {
                    isLoading ?
                        <>
                            <Skeleton size="xxxlarge" />
                            <Skeleton />
                        </> :
                        <>
                            <h2 className={css.barHeader}>
                                <b>{formatNumber(data?.hand_count_cur_rank_plus1)}</b> {t("hand", { count: data?.hand_count_cur_rank_plus1 })}
                            </h2>
                            <Bar value={data?.hand_count_cur_rank_plus1} amount={data?.min_up_hand_count_plus1} label={false}
                                color={Number(data?.hand_count_cur_rank_plus1) > 15000 ? EColors.green : EColors.red} />
                            <div className="caption">{t("Minimum")} 15000</div>
                        </>
                }
            </Paper>

            <Paper className={classNames("indicator", css.card)}>
                <h4 className="header">
                    {t('Hands from last rang change')}
                    <Helper path="handRangChange" />
                </h4>
                {
                    isLoading ?
                        <Skeleton size="xxxlarge" /> :
                        <h2 className={classNames(
                            css.barHeader,
                            `color_${Number(data?.total_hand_count) >= 100000 ? 1 : -1}`
                        )}>
                            <b>{formatNumber(data?.total_hand_count)}</b> {t("hand", { count: data?.total_hand_count })}
                        </h2>
                }
                {
                    isLoading ?
                        <Skeleton size="xsmall" /> :
                        data &&
                        <Bar
                            value={Math.min(data.total_hand_count, 100000)}
                            amount={100000}
                            label={false}
                            color={Math.min(data.total_hand_count, 100000) === 100000 ? EColors.green : EColors.yellow}
                        />
                }

                <div className="caption">{t("last_rank_subtitle", { decr: "40,000", incr: "30,000" })}</div>
            </Paper>

            <Paper className={classNames("indicator", css.card)}>
                <h4 className="header">
                    {t("Last rang change")}
                    {/*<Helper path="lastRangChange" />*/}
                </h4>
                {
                    isLoading ?
                        <Skeleton size="xxxlarge" /> :
                        <div className="value">
                            {data?.last_rang_change ? (data?.last_rang_change === "up" ? `+1` : "-1") : "-"}
                            {
                                data?.last_rang_change ?
                                    data.last_rang_change === "up" ?
                                        <IconGrowUp size="xxlarge" className="color-green" /> :
                                        <IconGrowDown size="xxlarge" className="color-red" /> :
                                    null
                            }
                        </div>
                }
                {
                    isLoading ?
                        <Skeleton size="xsmall" /> :
                        data?.last_rang_change_date &&
            <div
                className="caption">{format(new Date(data?.last_rang_change_date), "LLLL yyyy", { locale: localeMap[i18n.language] })}</div>
                }
            </Paper>
        </>
    );
};
