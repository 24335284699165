import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

import { Bar, formatNumber, getEnumKeyByValue, IError, Paper, Row, Skeleton, Table, Tabs } from '@funfarm/kit';

import { EPeriod, ISelectEstimates } from "types";
import { getSelectEstimates } from "actions";

import { Helper } from "components/Helper";

import css from "./info.module.scss";


export const SelectRating = () => {
    const { t } = useTranslation();

    const [period, setPeriod] = useState<keyof typeof EPeriod>(getEnumKeyByValue(EPeriod, EPeriod["current_month"]));

    const { isLoading, data } = useQuery<ISelectEstimates, IError>({
        queryKey: ["select", "info", period],
        queryFn: () => getSelectEstimates(period)
    });


    const onChangePeriod = (period: string | number) => {
        setPeriod(period as keyof typeof EPeriod);
    };


    return (
        <Paper header={t("Select rating")} className={css.selectRating} helper={<Helper path="infoSelectRating" />}>
            <Tabs tabChecked={period} onChange={onChangePeriod}>
                <Row spaceBetween>
                    <h1>{data && formatNumber(data.total_score)}</h1>

                    <Row gap={1} align="center">
                        <Tabs.Header className={css.tabHeader} index={"current_month"}>{t(EPeriod.current_month)}</Tabs.Header>
                        <Tabs.Header className={css.tabHeader} index={"prev_month"}>{t(EPeriod.prev_month)}</Tabs.Header>
                        <Tabs.Header className={css.tabHeader} index={"3m"}>{t(EPeriod["3m"])}</Tabs.Header>
                        <Tabs.Header className={css.tabHeader} index={"current_year"}>{t(EPeriod.current_year)}</Tabs.Header>
                        <Tabs.Header className={css.tabHeader} index={"prev_year"}>{t(EPeriod.prev_year)}</Tabs.Header>
                        <Tabs.Header className={css.tabHeader} index={"alltime"}>{t(EPeriod.alltime)}</Tabs.Header>
                    </Row>
                </Row>


                <Table cells={14} className={css.srTable} view="condensed">
                    <Table.Row className={css.header}>
                        <Table.Cell colspan={4} />
                        <Table.Cell colspan={8} />
                        <Table.Cell align="center">{t('current')}</Table.Cell>
                        <Table.Cell align="center" className={css.max}>{t('max')}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colspan={4} className={css.title}>{t('Month distance')}</Table.Cell>
                        <Table.Cell colspan={8}>
                            {
                                isLoading ?
                                    <Skeleton /> :
                                    <Bar value={data?.mtt_count_score} amount={data?.max_mtt_count_score} showValues="none" />
                            }
                        </Table.Cell>
                        <Table.Cell align="center">{data?.mtt_count_score}</Table.Cell>
                        <Table.Cell align="center" className={css.max}>{data?.max_mtt_count_score}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colspan={4} className={css.title}>{t('Active rooms')}</Table.Cell>
                        <Table.Cell colspan={8}>
                            {
                                isLoading ?
                                    <Skeleton /> :
                                    <Bar value={data?.active_rooms_score} amount={data?.max_active_rooms_score} showValues="none" />
                            }
                        </Table.Cell>
                        <Table.Cell align="center">{data?.active_rooms_score}</Table.Cell>
                        <Table.Cell align="center" className={css.max}>{data?.max_active_rooms_score}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colspan={4} className={css.title}>{t('Tourn. count per session')}</Table.Cell>
                        <Table.Cell colspan={8}>
                            {
                                isLoading ?
                                    <Skeleton /> :
                                    <Bar value={data?.mtt_per_session_score} amount={data?.max_mtt_per_session_score} showValues="none" />
                            }
                        </Table.Cell>
                        <Table.Cell align="center">{data?.mtt_per_session_score}</Table.Cell>
                        <Table.Cell align="center" className={css.max}>{data?.max_mtt_per_session_score}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colspan={4} className={css.title}>{t('ABI to rang ABI')}</Table.Cell>
                        <Table.Cell colspan={8}>
                            {
                                isLoading ?
                                    <Skeleton /> :
                                    <Bar value={data?.abi_rate_score} amount={data?.max_abi_rate_score} showValues="none" />
                            }
                        </Table.Cell>
                        <Table.Cell align="center">{data?.abi_rate_score}</Table.Cell>
                        <Table.Cell align="center" className={css.max}>{data?.max_abi_rate_score}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colspan={4} className={css.title}>{t('Each active room')}</Table.Cell>
                        <Table.Cell colspan={8}>
                            {
                                isLoading ?
                                    <Skeleton /> :
                                    <Bar value={data?.rooms_score} amount={data?.max_rooms_score} showValues="none" />
                            }
                        </Table.Cell>
                        <Table.Cell align="center">{data?.rooms_score}</Table.Cell>
                        <Table.Cell align="center" className={css.max}>{data?.max_rooms_score}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colspan={4} className={css.title}>{t('Filling by tournament types')}</Table.Cell>
                        <Table.Cell colspan={8}>
                            {
                                isLoading ?
                                    <Skeleton /> :
                                    <Bar value={data?.types_score} amount={data?.max_types_score} showValues="none" />
                            }
                        </Table.Cell>
                        <Table.Cell align="center">{data?.types_score}</Table.Cell>
                        <Table.Cell align="center" className={css.max}>{data?.max_types_score}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colspan={4} className={css.title}>{t('Reentry')}</Table.Cell>
                        <Table.Cell colspan={8}>
                            {
                                isLoading ?
                                    <Skeleton /> :
                                    <Bar value={data?.reentry_score} amount={data?.max_reentry_score} showValues="none" />
                            }
                        </Table.Cell>
                        <Table.Cell align="center">{data?.reentry_score}</Table.Cell>
                        <Table.Cell align="center" className={css.max}>{data?.max_reentry_score}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colspan={4} className={css.title}>{t('Early exits')}</Table.Cell>
                        <Table.Cell colspan={8}>
                            {
                                isLoading ?
                                    <Skeleton /> :
                                    <Bar value={data?.early_early_mid_score} amount={data?.max_early_early_mid_score} showValues="none" />
                            }
                        </Table.Cell>
                        <Table.Cell align="center">{data?.early_early_mid_score}</Table.Cell>
                        <Table.Cell align="center" className={css.max}>{data?.max_early_early_mid_score}</Table.Cell>
                    </Table.Row>
                </Table>
            </Tabs>
        </Paper>
    );
};
